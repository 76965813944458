<template>
  <div class="canvas-container">
    <b-overlay
      :show="isGraphLoadingStatus"
      variant="transparent"
      :opacity="1"
      rounded="sm"
      class="canvas"
    >
      <ontology-view-joint ref="ontologyView" v-if="!isGraphLoadingStatus" :update-object="updateObject" @sidebar="onSidebar" />
    </b-overlay>

    <b-sidebar
      id="ontologyDetailsSidebar"
      width="450px"
      bg-variant="white"
      right
      no-close-on-route-change
      :visible.sync="isSidebarVisible"
      @hidden="isSidebarVisible = false"
    >
      <resize-bar sidebar-id="ontologyDetailsSidebar" />
      <EntityDetails :stereotype="stereotype" @clicked="doUpdate" />
    </b-sidebar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OntologyViewJoint from '@/components/Domain/OntologyTreeViewJoint/OntologyViewJoint.vue'
import EntityDetails from '@/components/Domain/EntityDetails.vue'
import ResizeBar from '@core/components/resize-bar.vue'

export default {
  name: 'Ontology',
  components: {
    ResizeBar,
    OntologyViewJoint,
    EntityDetails,
  },
  data() {
    return {
      updateObject: null,
      isSidebarVisible: false,
      isGraphLoadingStatus: false,
      mini: false,
      contextMenuWidth: (localStorage.getItem('ontologySidebarWidth') || 450),
    }
  },
  computed: {
    ...mapState('domainModel', ['composition_tree', 'selected_entity2']),
    currentRouteName() {
      return this.$route.name
    },
    stereotype() {
      if (!this.selected_entity2 || !this.isSidebarVisible) return ''
      if (this.isTreatedLikeStereotype(this.selected_entity2.context.labels, 'Performer')) return 'Performer'
      if (this.isTreatedLikeStereotype(this.selected_entity2.context.labels, 'Function')) return 'Function'
      return 'Base'
    },
  },
  methods: {
    isTreatedLikeStereotype(labels, treatedLike) {
      // does any specific component label get treated_like component stereotype?
      const { stereotypes } = this.$store.state.constants
      // eslint-disable-next-line
      for (const specific of labels) {
        if (specific === treatedLike) return true
        const stereotypeConstant = stereotypes.find(s => s.name === specific)
        if (stereotypeConstant && stereotypeConstant?.treat_like?.find(t => t === treatedLike)) {
          return true
        }
      }
      return false
    },
    onSidebar(isVisible) {
      this.isSidebarVisible = isVisible
    },
    doUpdate() {
      this.$bvModal.hide('replace-names-modal')
      console.log('Updated Object: ', this.selected_entity2)
      this.updateObject = this.selected_entity2
      this.$refs.ontologyView.reloadGraph()
    },
  },
}
</script>

<style scoped lang="scss">
body {
  height: 60vh;
  box-sizing: border-box;
  margin: 0;

  .content-wrapper {
    .content-body {
      width: 100%;
      height: 100%;
    }
  }

  .canvas {
    width: 100%;
    height: 100%;

    .joint-paper {
      border: 1px solid #000000;
    }
  }

  .toolbar-text {
    display: none;
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu.scss';

.canvas-container {
  width: revert;
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  top: 7rem;
}

.entity-pane {
  border-left: 1px dotted;
  overflow-y: auto;
}

</style>
